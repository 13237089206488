<template lang="pug">
main.max-w-lg.mx-auto.flex.flex-col.items-center.mt-8.px-8.sm_mt-16.lg_mt-32
  .header.hidden.flex-col.items-center.mb-8.sm_flex
    MarkLogo.w-10.h-10.mb-6.text-gray-900
    .text-3xl
      p Log in to your account
  .content.text-sm.w-full
    p.font-medium.mb-0 Log in with
    .flex.justify-center.flex-col.sm_flex-row
      VButton.mr-2.w-full.mt-3.flex-1.flex-shrink-0.sm_w-auto(
        size="md"
        @click="connectWithAlternateService('v2/oauth2/login/google-oauth2/', 'login')"
        v-analytics="'loginGoogleLoginSelected'"
      )
        .flex.items-center.justify-center.text-gray-700
          Google.mr-1.w-4
          span.inline-block.font-medium Google
      VButton.mr-2.w-full.mt-3.flex-1.flex-shrink-0.sm_w-auto(
        size="md"
        @click="connectWithAlternateService('v2/oauth2/login/linkedin-oauth2/', 'login')"
        v-analytics="'loginLinkedInLoginSelected'"
      )
        .flex.items-center.justify-center.text-gray-700
          LinkedIn.mr-1.w-4
          span.inline-block.font-medium LinkedIn
      VButton.mr-2.w-full.mt-3.flex-1.flex-shrink-0.sm_w-auto(
        size="md"
        @click="connectWithAlternateService('v2/oauth2/login/apple-id/', 'login')"
        v-analytics="'loginAppleLoginSelected'"
      )
        .flex.items-center.justify-center.text-gray-700
          Apple.mr-1.w-4
          span.inline-block.font-medium Apple
      VButton.mr-2.w-full.mt-3.flex-1.flex-shrink-0.sm_w-auto(
        size="md"
        @click="connectWithAlternateService('v2/oauth2/login/microsoft-graph/', 'login')"
        v-analytics="'loginMicrosoftLoginSelected'"
      )
        .flex.items-center.justify-center.text-gray-700
          Microsoft.mr-1.w-4
          span.inline-block.font-medium Microsoft
    .flex.justify-center.text-gray-700.my-8
      .separator.w-full.align-center
        p.mb-0.text-gray-700 Or
    form.login-form(@submit.prevent="submit")
      .email.mb-8
        label.text-gray-700(for="email_address") Email
        VInput(
          type="email"
          name="email_address"
          placeholder="you@example.com"
          @input="hasError = false"
          v-model="form.email_address"
          :error="hasError"
          :maxlength="255"
        )
      .password
        nuxt-link.float-right.text-copper-700.font-medium.underline.cursor-pointer.hover_text-gray-900(to="/forgot-password") Forgot your password?
        label.text-gray-700(for="password") Password
        VInput(type="password" name="password" @input="hasError = false" v-model="form.password" :error="hasError" :maxlength="255")
        .error.text-sm.text-red-800(v-if="hasError" data-testid="login-error") {{ errorMessage }}
      .submit.mt-6
        VButton.w-full(variant="dark" type="submit" v-analytics="'loginSubmitted'")
          p.mb-0 Log In
    .sign-up.mt-8.mb-32
      .flex.text-base
        p.text-gray-600 Don't have an account?&nbsp;
        a.text-copper-700.underline.cursor-pointer.hover_text-gray-900(@click="gotoSignUpPage") Sign Up
</template>

<script lang="ts" setup>
import MarkLogo from '~/assets/images/mark-logo.svg'
import Apple from '@/components/icons/Apple.svg'
import LinkedIn from '@/components/icons/LinkedIn.svg'
import Microsoft from '@/components/icons/Microsoft.svg'
import Google from '@/components/icons/Google.svg'
import { FetchError } from 'ofetch'

const router = useRouter()
const route = useRoute()
const { $analytics } = useNuxtApp()
const { state: user, create } = useUser()
const hasError = ref(false)
const errorMessage = ref('Incorrect email address and/or password.')
const form = reactive({
  email_address: null,
  password: null,
})

onMounted(() => {
  if (user.value.user) {
    router.push({ name: 'user-profile' })
    return
  }

  if (route.query.redirectedFrom === '/') {
    router.replace('login')
  }

  $analytics.track('showedScreenLogin')
})

const onError = (error?: { response: { status: number; data: { message: string } } }) => {
  if (error?.response?.status === 403) {
    errorMessage.value = error.response.data.message
  } else {
    errorMessage.value = 'Incorrect email address and/or password.'
  }

  hasError.value = true
}

const { connectWithAlternateService, oauthSuccess } = useOauthServices({ onError })

const gotoSignUpPage = () => {
  const nextRoute = route.query.redirectedFrom ?? window.location.pathname
  router.push({ name: 'signup', query: { ...route.query, redirectedFrom: nextRoute } })
}

const submit = async () => {
  try {
    await create({ email_address: form.email_address ?? '', password: form.password ?? '' })

    oauthSuccess()
  } catch (e) {
    if (e instanceof FetchError) {
      $analytics.track('loginError', {
        errorMessage: e?.data.message,
      })
      onError(e.data)
    }
  }
}
</script>

<style lang="stylus" scoped>
.separator
  display: flex
  align-items: center
  text-align: center

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #E1E1E1;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}
</style>
